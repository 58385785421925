'use client'

import { Box, Button, Flex, Icon, Link } from '@chakra-ui/react'
import React, { useState } from 'react'
import { colors } from 'styles/foundations'
import { FaFacebook } from 'react-icons/fa'
import { FaInstagram } from 'react-icons/fa'
import { FaTwitter } from 'react-icons/fa'
import { FaYoutube } from 'react-icons/fa'
import ScrollProgressBar from 'components/scroll-progress'
import NavDrawer from './nav-drawer/'
import { Logo } from 'components/logo'
import { IoMdMenu } from 'react-icons/io'
import '../home/style/styles.css'

export default function NavBar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }
  return (
    <Box pos={'fixed'} left={0} w={'full'} shadow="sm" bg={colors.brand.white} zIndex="popover">
      <ScrollProgressBar />
      <Flex
        w={{ base: 'full' }}
        mx="auto"
        px={{ base: '2rem', md: '4rem', xl: '6rem' }}
        justifyContent="space-between"
        alignItems={'center'}
        h={28}>
        <Box width="20%">
          <Logo />
        </Box>
        {/* <Flex align={'center'} gap={16}> */}
        <Box
          display={{ md: 'flex', base: 'none' }}
          alignItems={'center'}
          gap={5}
          width={'20%'}
          justifyContent={'center'}>
          <Link
            target={'_blank'}
            href="https://www.facebook.com/profile.php?id=100094364470081&locale=hi_IN&paipv=0&eav=AfbNZE8OtIIhwse5wlxg1Yc1gVjT1T5xO7MpoXnw84Y0-Kl77wmKkcyOKuJrVIlQM-I">
            <Icon as={FaFacebook} boxSize={'10'} />
          </Link>
          <Link target={'_blank'} href="https://www.instagram.com/lint_finance/">
            <Icon as={FaInstagram} boxSize={'10'} />
          </Link>
          <Link target={'_blank'} href="https://twitter.com/lint_finance">
            <Icon as={FaTwitter} boxSize={'10'} />
          </Link>
          <Link target={'_blank'} href="https://www.youtube.com/@LintFinance">
            <Icon as={FaYoutube} boxSize={'10'} />
          </Link>
        </Box>
        {/* <Icon as={RiMenuFill} display={{ md: 'none', base: 'flex' }} /> */}
        <Flex>
          <Icon
            as={IoMdMenu}
            boxSize={12}
            onClick={toggleDrawer}
            display={{ md: 'none', base: 'flex' }}
          />

          <Box display={{ md: 'flex', base: 'none' }} alignItems={'center'} gap={8} width={'20%'}>
            {/* <Link href="/auth/login" variant={'unstyled'} height={14}>
                <Button size="16px" variant={'unstyled'} height={14} px={5}>
                  Login
                </Button>
              </Link> */}
            <Link href="/blog" variant={'unstyled'} height={14}>
              <Button size="16px" variant={'unstyled'} height={14}>
                Read our blog
              </Button>
            </Link>
            <Link href="https://lint.onelink.me/8QeB/1l651f8u">
              <Button
                size="16px"
                variant={'unstyled'}
                border={`1px solid ${colors.brand.darkColors[0]}`}
                height={14}
                px={5}>
                Get the app
              </Button>
            </Link>
          </Box>
        </Flex>
        {/* </Flex> */}
      </Flex>

      <NavDrawer toggleDrawer={toggleDrawer} isDrawerOpen={isDrawerOpen} />
    </Box>
  )
}
